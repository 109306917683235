<template lang = "pug">
fi-modal.customer-role-new-modal(
  ref     = 'modal'
  v-model = 'showModal'
  size    = 'lg'
  :title  = '$t(title)'
  lazy
  @hide   = 'onHide'
  @shown  = 'resetData'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(
      :label     = '$t("relatedCustomer")'
      :state     = '!$v.referredCustomerId.$error'
      :disabled  = 'createNewCustomer'
      label-cols = 3
    )
      template(#error)
        .error-message(v-if = '!$v.referredCustomerId.required') {{ $t('common:required') }}
      .input-group
        fi-customer-select(
          v-model      = 'selectedCustomerModel'
          :loading     = '$vueLoading.isLoading("customers:system:fetch")'
          :placeholder = '$t("selectCustomer")'
          :class       = '{ "is-invalid": $v.referredCustomerId.$error }'
        )
        .input-group-append
          button.btn.btn-primary(
            @click.prevent = 'createNewCustomer = true'
          ) {{ $t('createCustomer') }}

    .form-group
      .col-sm-9.offset-sm-3
        customer-new-brief(
          v-if    = 'createNewCustomer'
          private-customer
          @cancel = 'createNewCustomer = false'
          @submit = 'onCreateCustomer'
        )

    fi-form-field(
      :label     = '$t("relationType")'
      :disabled  = 'createNewCustomer'
      label-cols = 3
    )
      fi-select(
        v-model.number = 'formData.relationTypeId'
        :options       = 'relationTypes'
        sm
        required
      )
    fi-form-field(
      :label     = '$t("validFrom")'
      :disabled  = 'createNewCustomer'
      label-cols = 3
    )
      fi-datepicker(
        v-model      = 'formData.validFrom'
        :placeholder = '$t("datePlaceholder")'
        sm
      )
    fi-form-field(
      :label     = '$t("validTo")'
      :disabled  = 'createNewCustomer'
      label-cols = 3
    )
      fi-datepicker(
        v-model      = 'formData.validTo'
        :placeholder = '$t("datePlaceholder")'
        sm
      )
    fi-form-field(
      :label     = '$t("portalAccess")'
      :disabled  = 'createNewCustomer'
      label-cols = 3
    )
      fi-switch(v-model = 'formData.accessToPortal')
    fi-form-field.animated.fadeIn(
      v-if       = 'formData.accessToPortal'
      :label     = '$t("permissions")'
      :disabled  = 'createNewCustomer'
      label-cols = 3
    )
      b-check-group(
        v-model = 'formData.permissions'
        :options = 'optionsFromClassifier("relationPermissions", true)'
      )
    fi-form-field(
      :label     = '$t("comment")'
      :disabled  = 'createNewCustomer'
      label-cols = 3
    )
      textarea.form-control.form-control-sm(v-model = 'formData.comment')
  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'saving || createNewCustomer'
      @click.prevent = 'onSubmit'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
</template>


<script>
import FiDatepicker from '@/components/FiDatepicker'
import FiFormField from '@/components/FiFormField'
import FiModal from '@/components/FiModal'
import FiMultiselect from '@/components/FiMultiselect'
import FiSelect from '@/components/FiSelect'
import { validation, validators } from '@/validation'
import CustomerNewBrief from '@/views/customers/CustomerNewBrief'
import pick from 'lodash/pick'
import { mapActions, mapGetters } from 'vuex'
import FiSwitch from '@/components/FiSwitch'
import FiCustomerSelect from '@/components/FiCustomerSelect'

export default {
  name: 'customer-relation-new-modal',

  components: {
    FiCustomerSelect,
    FiSwitch,
    CustomerNewBrief,
    FiMultiselect,
    FiSelect,
    FiFormField,
    FiDatepicker,
    FiModal
  },

  mixins: [validation],

  props: {
    value: {
      type: Boolean,
      required: true
    },
    relation: {
      type: Object,
      default: null
    },
    loader: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'newRelation',
      validator: (value) => ~['newRelation', 'editRelation'].indexOf(value)
    },
    relationType: {
      type: String,
      default: 'referred',
      validator: (value) => ~['referred', 'representor'].indexOf(value)
    },
    customer: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      createNewCustomer: false,
      selectedCustomer: null,
      referredCustomerId: '',
      customerId: '',
      formData: {
        accessToPortal: false,
        permissions: [],
        relationTypeId: '',
        validFrom: null,
        validTo: null,
        comment: null
      }
    }
  },

  computed: {
    ...mapGetters('customers', ['customerName', 'isCorporateCustomer']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierList']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    selectedCustomerModel: {
      get () {
        return this.selectedCustomer
      },
      set (selected) {
        this.selectedCustomer = selected
        this.referredCustomerId = (selected && selected.id) || ''
      }
    },
    saving () {
      return this.$vueLoading.isLoading(this.loader)
    },
    relationTypes () {
      const privateRelation = !(this.isCorporateCustomer(this.customer) || this.isCorporateCustomer(this.selectedCustomer))
      return Object.values(this.classifierList('relationTypes'))
        .filter(({ onlyPrivate }) => onlyPrivate === privateRelation)
        .map(({ id, human }) => ({ text: human, value: id }))
    }
  },

  validations: {
    referredCustomerId: {
      required: validators.required
    }
  },

  methods: {
    ...mapActions('customers', ['createCustomerRelation', 'updateCustomerRelation']),
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
      if (this.relation) {
        this.formData = pick(this.relation, Object.keys(this.formData))
        this.selectedCustomerModel = this.relation?.referredCustomer
      }

      this.$v.$reset()
    },
    onHide (e) {
      if (this.saving) e.preventDefault()
    },
    async onSubmit () {
      if (this.validate()) {
        this.$emit('submit', {
          data: { id: this.relation?.id, relationData: this.formData, referredCustomerId: this.referredCustomerId },
          done: () => this.$refs.modal.hide()
        })
      }
    },
    onCreateCustomer ({ customer }) {
      this.createNewCustomer = false
      this.selectedCustomerModel = customer
    }
  }
}
</script>


<i18n>
en:
  newRelation:     "New relation"
  editRelation:    "Edit relation"
  relatedCustomer: "Related customer"
  selectCustomer:  "Search existing"
  createCustomer:  "Create new"
  relationType:    "Relation type"
  validFrom:       "Valid from"
  validTo:         "Valid until"
  datePlaceholder: "Select date"
  mainContact:     "Main contact"
  comment:         "Comment"
  or:              "Or"
  portalAccess:    "Access to portal"
  permissions:     "Permissions"
et:
  newRelation:     "New relation"
  editRelation:    "Edit relation"
  relatedCustomer: "Related customer"
  selectCustomer:  "Search existing"
  createCustomer:  "Create new"
  relationType:    "Relation type"
  validFrom:       "Valid from"
  validTo:         "Valid until"
  datePlaceholder: "Select date"
  mainContact:     "Main contact"
  comment:         "Comment"
  or:              "Or"
  portalAccess:    "Access to portal"
  permissions:     "Permissions"
ru:
  newRelation:     "New relation"
  editRelation:    "Edit relation"
  relatedCustomer: "Related customer"
  selectCustomer:  "Search existing"
  createCustomer:  "Create new"
  relationType:    "Relation type"
  validFrom:       "Valid from"
  validTo:         "Valid until"
  datePlaceholder: "Select date"
  mainContact:     "Main contact"
  comment:         "Comment"
  or:              "Or"
  portalAccess:    "Access to portal"
  permissions:     "Permissions"
</i18n>
